import React from 'react';
import './header.css';
import vector from '../../Vector.png';
import {ReactComponent as RightArrow} from '../../svgs/right-arrow.svg';
import group from '../../assets/heroImg.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
  return (
    <>
    <div className='hero-block'>
    <div className='hero-text'>
         <div className="heading">
             <p>Syndicate Tips</p>
         </div>
         <div className="para"> <p> Postanite i Vi deo uspešne priče u sportskom klađenju na Balkanu i ostvarite maksimalni profit. Naš tim stručnjaka pažljivo prati sportske trendove, analizira podatke i pruža vam precizne preporuke kako biste ostvarili najveći mogući profit. </p> </div>                
     </div>

     <div className='img-container-custom'>
        <img src={group} className='hero-image' alt="" />

     </div>



    </div>

    </>
  )
}

export default Header



// <div className="container-fluid hero-block mb-5">
// <div className="hero-block-main ">

// <div className="contents d-lg-flex justify-content-center">
//     <div className="">
//     <div className="heading">
//             <p>Syndicate Tips</p>
//         </div>
//         <div className="para"> <p> Postanite i Vi deo uspešne priče u sportskom klađenju na Balkanu i ostvarite maksimalni profit. Naš tim stručnjaka pažljivo prati sportske trendove, analizira podatke i pruža vam precizne preporuke kako biste ostvarili najveći mogući profit. </p> </div>
                       
//     </div>
//     <div>
//         <img className='hero-image' src={group}  />
//     </div>
// </div>

// </div>
// </div>