import React, {useState} from 'react';
import './gallery.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import FullScreenImage from '../fullScreenImage/fullScreenImage';

function importAll (r) {
  return r.keys ().map (r);
}

const imagesPremium = importAll (
  require.context ('../../assets/premium', false, /\.(png|jpe?g|svg)$/)
);
const imagesAlpha = importAll (
  require.context ('../../assets/alpha', false, /\.(png|jpe?g|svg)$/)
);

const Gallery = () => {
  const [option, setOption] = useState ('1');

  return (
    <div className="container-fluid  pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="gallery-heading mb-4">
              <h2>Prošli tiketi</h2>
            </div>
          </div>
        </div>

        <div className="text-center clickss">

          <div
            className={`links-one ${option === '1' ? 'selected-tiket' : ''}`}
            onClick={e => setOption ('1')}
          >
            <p className=" mt-3"> PREMIUM TICKETI </p>
          </div>

          <div
            className={`links-last ${option === '2' ? 'selected-tiket' : ''}`}
            onClick={e => setOption ('2')}
          >
            <p className="mt-3"> ALPHA TIKETI </p>

          </div>

        </div>

        {/* //premium portion */}

        {option === '1' &&
          <Carousel
            className="carousel"
            emulateTouch
            useKeyboardArrows={true}
            infiniteLoop={true}
            onClickItem={item => {
              const fullPage = document.querySelector ('#fullpage');
              const fullpageImg = document.querySelector ('#fullpage-img');
              fullpageImg.src = imagesPremium[item];
              fullPage.style.display = 'block';
            }}
            showArrows={true}
            showIndicators={false}
          >

            {imagesPremium.map ((item, i) => {
              return (
                <div key={i}>
                  <img className="carousel-image" src={item} />
                </div>
              );
            })}

          </Carousel>}
        {/* //alpha portion */}

        {option === '2' &&
          <Carousel
            className="carousel"
            emulateTouch
            useKeyboardArrows={true}
            infiniteLoop={true}
            onClickItem={item => {
              const fullPage = document.querySelector ('#fullpage');
              const fullpageImg = document.querySelector ('#fullpage-img');
              fullpageImg.src = imagesAlpha[item];
              fullPage.style.display = 'block';
            }}
            showArrows={true}
            showIndicators={false}
          >
            {imagesAlpha.map ((item, i) => {
              return (
                <div key={i}>
                  <img src={item} />
                </div>
              );
            })}
          </Carousel>}

      </div>
      <FullScreenImage />
    </div>
  );
};

export default Gallery;
