import React, { useEffect } from 'react';
import './style.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const  FullScreenImage = () => {

    useEffect(() => {
        const handleEsc = (event) => {
           if (event.keyCode === 27) {
                document.getElementById("fullpage").style.display = "none";
          }
        };
        window.addEventListener('keydown', handleEsc);
    
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
      }, []);


    return (
        <div id="fullpage">
            <TransformWrapper>
                <button className='escape-fullpage' onClick={() => {
                    const fullPage = document.querySelector('#fullpage');
                    fullPage.style.display = 'none';

                }}>x</button>
                <TransformComponent className="fullpage-img-container">
                    <img id='fullpage-img' src="image.jpg" alt="test" />
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}

export default FullScreenImage;