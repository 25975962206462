import React from "react";
import "./AFMarketing.css";
import bg from "../../assets/bgAF.png";

const AFMarketing = () => {
  return (
    <>
      <div id="affiliate" className="af-container-main container-fluid d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div  className="col-sm-12 AF-container">
              <img src={bg} className="bg-image" />
              <div>
                <div className="AF-heading">
                  <h1>Affiliate program</h1>
                  <p>
                    radi kao i svaki standardni program u celom svetu,mi vam
                    dajemo odredjenu sumu od clanarine za svakog ko nam se
                    pridruzi koristeci vas referal kod ,isto tako placamo vas i
                    naknadno kad god taj isti produzi clanarinu
                  </p>
                </div>
                <div className="level">
                    <h4>Level 1</h4>
                    <ul>
                      <li>1-10 referala</li>
                      <li>20% od uplate</li>
                      <li>10% od svake obnove</li>
                    </ul>
                </div>
                <div className="level">
                    <h4>Level 2</h4>
                    <ul>
                      <li>11-25 referala</li>
                      <li>25% od uplate</li>
                      <li>20% od svake obnove</li>
                    </ul>
                </div>
                <div className="level">
                    <h4>Level 3</h4>
                    <ul>
                      <li>25+ referala</li>
                      <li>30% od uplate</li>
                      <li>30% od svake obnove</li>
                    </ul>
                </div>
              </div>
              <a href="https://affiliate.syndicatetips.org" target="_blank" className="go-to-affiliate">Go to Affiliate</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AFMarketing;
