import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../Group 176.png";
import menu from "../../assets/menu.svg";
import './head.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Head = () => {

  return (
    <>


      <Navbar className='nav'  expand="lg">
      <Container>
        <Navbar.Brand className='logo-src' href="#home"><img className='logo-img' src={logo} alt="logo" /> </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto gap-3">
          <Nav.Link className='nav-link' href="#Za-nas"  >  Za nas  </Nav.Link>
          <Nav.Link className='nav-link' href="#Paketi"  >Paketi</Nav.Link>
            <Nav.Link className='nav-link' href="#Prosli-tiketi"  >Prosli tiketi</Nav.Link>
            <Nav.Link className='nav-link' href="#affiliate" >Affiliate program</Nav.Link>
            <Nav.Link className='nav-link' href="#Kladionice" >Kladionice</Nav.Link>
            <Nav.Link className='nav-link' href="#Metodi-Placanja" >Metodi Placanja</Nav.Link>
            <Nav.Link className='nav-link' href="#Kontakt" >Kontakt</Nav.Link>
      
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


      
    </>
  )
}

export default Head