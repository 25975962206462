import React, { useState } from "react";
import "./cards.css";
import visa from "../../visa-logo.png";
import mastercard from "../../Mastercard.png";
import shape from "../../Shape.png";
import western from "../../Western Union Logo Vector 1.png";

function importAll (r) {
  return r.keys ().map (r);
}

const icons = importAll (
  require.context ('../../assets/icons/currency', false, /\.(png|jpe?g|svg)$/)
);

const Cards = () => {

  return (
    <>
      <div className="container-fluid  pt-5 pb-5">
        <div className="container">
          <div className="row mt-4">
            <div className="cards-heading">
              <p>Metodi Plačanja</p>
            </div>
            <div className="card-para">
              <p>
              Za dodatne metode plačanja slobodno nas kontaktirajte
              </p>
            </div>
          </div>

          <div className="method-container  mt-3 gap-5 mb-5">
            {icons.map(item => {
              return (
              <div className="credit-card">
              {" "}
              <img src={item} />{" "}
            </div>)
            })}

          </div>


        </div>
      </div>
    </>
  );
};

export default Cards;
