import React from "react";
import "./footer.css";
import insta from "../../Instagram - Original.png";
import discord from "../../Discord - Original.png";
import google from "../../Google - Original.png";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footer d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center align-items-center footer-section">
            <div className="col-sm-12">
              <div style={{
                width: "100%"
              }} className="footer-text-2 d-flex align-items-center">
                <p style={{
                width: "100%",
                textAlign: "center"
              }}  className="mb-0">

                  2023 SYNDICATE. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
