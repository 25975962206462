import React from "react";
import "./plan.css";
import vecter2 from "../../Vector2.png";

const Plan = () => {
  return (
    <>
      <div className="container-fluid container-fluid-no-padding  pt-5 pb-5">
        <div className="container">
          <div className="row">
          <div className="heading-2">
                {/* <p>Plan & Pricing </p> */}
                <p> Paketi </p>
              </div>
            <div className="col card-cont-2">

              <div className="row justify-content-center card-custom mt-5 plan-section">
                <div className="col-md-3 d-flex card-cont justify-content-center">
                  <div className="one ">
                    <div className="heading-3">
                    
                      <h2> PREMIUM </h2>
                    </div>
                    <div className="vector-2">
                      <img src={vecter2} alt="" />
                    </div>
                    <div className="para-5  mt-2">
            
                      <p> 50 <sup>€</sup></p>
                    </div>
                    <div className="para-3 mt-1">
               
                      <span>uključuje</span>
                    </div>
                    <div className="para-4 ">
                          <span>Premium</span>
                          <span>Modeli</span>
                          <span>14 sportova</span>
                          <span>24 tipstera</span>
                    </div>
                  
                    {/* <div className="para-4">
                      <p>Per month</p>
                    </div>

                    <button className="btn-4 mt-3 " style={{ color: "white" }}>
                      Buy Standard
                    </button> */}
                  </div>
                </div>

                <div className="col-md-3 d-flex card-cont justify-content-center">
                  <div className="one ">
                  <span className="banner">Najpopularni</span>

                    <div className="heading-3">
                    
                      <h2> ALPHA </h2>
                    </div>
                    <div className="vector-2">

                      <img src={vecter2} alt="" />
                    </div>
                    <div className="para-5  mt-2">
            
                      <p> 120 <sup>€</sup></p>
                    </div>
                    <div className="para-3 mt-1">
               
                      <span>uključuje</span>
                    </div>
                    <div className="para-4 ">
                          <span>Premium</span>
                          <span>Modeli</span>
                          <span>Admin</span>
                          <span>A.I</span>
                          <span>14 sportova</span>
                          <span>40 tipstera</span>
                    </div>
                  
                    {/* <div className="para-4">
                      <p>Per month</p>
                    </div>

                    <button className="btn-4 mt-3 " style={{ color: "white" }}>
                      Buy Standard
                    </button> */}
                  </div>
                </div>
                <div className="col-md-3 d-flex card-cont justify-content-center">
                  <div className="one ">
                    <div className="heading-3">
                    
                      <h2> INSIDER </h2>
                    </div>
                    <div className="vector-2">
                      <img src={vecter2} alt="" />
                    </div>
                    <div className="para-5  mt-2">
            
                      <p> 240 <sup>€</sup></p>
                    </div>
                    <div className="para-3 mt-1">
               
                      <span>uključuje</span>
                      
                    </div>
                    <div className="para-4 ">
                          <span>NBA Insider povrede</span>
                          <span>College Kosarka</span>
                          <span>College Americki fudbal</span>
                          <span>Premium</span>
                          <span>Modeli</span>
                          <span>Admin</span>
                          <span>A.I</span>
                          <span>14 sportova</span>
                          <span>40 tipstera</span>
                    </div>
                  
                    {/* <div className="para-4">
                      <p>Per month</p>
                    </div>

                    <button className="btn-4 mt-3 " style={{ color: "white" }}>
                      Buy Standard
                    </button> */}
                  </div>
                </div>


                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
